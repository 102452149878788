<template>
  <div class="group-record__item" @click="checkInfo">
    <div class="group-record__item--info">
      {{$t('group.tipGroupStartTime')}}： {{createdAt}}
      <div class="item-title">
        <p>
          {{discount}}<span>% off</span>
        </p>
        <strong v-if="info.couponAvailable">{{$t('group.labelCouponCheck')}}</strong>
        <strong v-else>{{$t('group.labelCouponGroup')}}</strong>
      </div>
    </div>
    <div class="group-record__item--list">
      <div class="item--list is-clearfix">
        <div class="record-item" v-for="(item, index) in info.members" :key="index"
             :style="{backgroundImage: `url(${item.avatar})`}">
          <span v-if="item.isCaptain">{{$t('group.tipGroupHead')}}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
export default {
  name: 'group-record-item',
  props: {
    info: {
      type: Object,
      default: function () {
        return {
          members: []
        }
      }
    }
  },
  data: () => ({
  }),
  computed: {
    createdAt () {
      return moment(this.info.createdAt).format('YYYY-MM-DD HH:mm:ss')
    },
    discount () {
      if (!this.info.discount) return 0
      if (this.info.discount <= 1) {
        return Math.round((1 - this.info.discount) * 100)
      }
      return this.info.discount
    }
  },
  created () {},
  methods: {
    checkInfo () {
      if (this.info.couponAvailable && this.info.discount !== 1) {
        this.$emit('click')
      }
    }
  }
}
</script>

<style type="text/scss" lang="scss">
  $gutter: 16px;
.group-record__item {
  background: url(../../../static/icons/group-texture.png) no-repeat top right/40%, linear-gradient(315deg,rgba(153,153,153,1) 0%,rgba(102,102,102,1) 100%);
  box-shadow: 0 10px 15px 0 rgba(0,0,0,0.16);
  border-radius: 10px;
  color: #999;
  overflow: hidden;
  margin-bottom: 1.5em;
  &--info {
    padding: 8px $gutter;
    .item-title {
      font-size: 18px;
      display: flex;
      align-items: center;
      color: white;
      p {
        flex: 1;
        font-size: 40px;
        font-weight: 500;
        span {
          font-size: 18px;
        }
      }
      strong {
        color: inherit;
      }
    }
  }
  &--list {
    padding: 11px $gutter;
    background:rgba(255,255,255, 0.7);
    .item--list {
      padding: 10px 2px 12px;
      height: 66px;
      overflow: hidden;
      overflow-x: auto;
      white-space: nowrap;
      text-align: left;
      margin: 0 -16px;
    }
    .record-item {
      height: 44px;
      width: 44px;
      display: inline-block;
      margin: 0 14px;
      border-radius: 50%;
      box-shadow: 0 0 5px 2px rgba(0,0,0,.1);
      position: relative;
      overflow: hidden;
      background: {
        size: cover;
        repeat: no-repeat;
      };
      span {
        position: absolute;
        display: block;
        bottom: 0;
        width: 100%;
        height: 40%;
        text-align: center;
        color: white;
        font-weight: 500;
        z-index: 1;
        font-size: 13px;
        background:linear-gradient(135deg,rgba(245,102,105,1) 0%,rgba(248,172,132,1) 100%);
      }
    }
  }
}
</style>
